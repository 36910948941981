import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { answerPage, getHomeRoute, getQuizRoute } from './routes/routes';

const Home = React.lazy(()=>import('./routes/HomePage'));
const Quiz = React.lazy(()=>import('./routes/Quiz/index'))
const Answers = React.lazy(()=>import('./routes/answers'))
function App() {
  return (
    <>
    <BrowserRouter>
    <Suspense fallback={<div>Trwa ładowanie strony</div>}>
    <Routes>
      <Route path={getHomeRoute()} element={<Home />} />
      <Route path={getQuizRoute()} element={<Quiz />}/>
      <Route path={answerPage()} element={<Answers />} />
    </Routes>

    
    </Suspense>
    </BrowserRouter>
    </>
  );
}

export default App;
